import { AiFillMail, AiOutlineMail } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { useState } from "react";
import Spinner from "./spinner";
import { addSubscriber } from "../controllers/subscribersControllers";
import toast from "react-hot-toast";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className=" border-white border-opacity-10">
      <div className=" w-10/12 mx-auto py-8 ">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            const payload = {
              email: e.target.email.value,
            };
            addSubscriber(payload)
              .then((response) => {
                setLoading(false);
                e.target.email.value = "";
                toast.success("Subscribed successfully");
              })
              .catch((err) => {
                e.target.email.value = "";
                toast.error(err.response.data.message);
                setLoading(false);
              });
          }}
          className="grid grid-cols-12 justify-between gap-2 pb-6 border-b border-border border-opacity-20"
        >
          <div className=" col-span-12 md:col-span-4 2xl:col-span-3">
            <h1 className="text-xl md:text-3xl font-bold">
              Join Our Newsletter to Keep up to date with us
            </h1>
          </div>
          <div className=" col-span-12 md:col-span-4 2xl:col-span-6"></div>
          <div className="col-span-12 md:col-span-4 2xl:col-span-3">
            <div className=" bg-[#434343] flex items-center px-2 pl-3 rounded-md py-1">
              <AiOutlineMail className="text-4xl" />
              <input
                placeholder="Enter your email"
                required
                name="email"
                type="email"
                className="bg-transparent text-sm w-full border-0 placeholder:text-white focus:border-transparent focus:ring-transparent "
              />
              <button
                type="submit"
                className="py-1 border border-primary hover:scale-105 w-48 flex justify-center items-center  text-white font-medium bg-primary transition-all duration-200 hover:text-white rounded-md px-6"
              >
                {loading ? <Spinner /> : "Subscribe"}
              </button>
            </div>
          </div>
        </form>
        <div className="grid grid-cols-12 mt-12">
          <div className=" col-span-8">
            <img className="h-10 cursor-pointer" src="/logo.svg" />
          </div>
          <div className=" col-span-4 ">
            <div className="grid grid-cols-12 text-muted">
              <div className=" col-span-12 md:col-span-6"></div>
              <div className=" col-span-12 md:col-span-6">
                <h1 className="text-white font-bold mb-2">Resources</h1>
                {/* <p>About Us</p> */}
                <div className="flex flex-col ">
                  <a
                    href="/terms-of-use"
                    className="text-sm hover:text-primary"
                  >
                    Terms of use
                  </a>
                  <a
                    href="/privacy-policy"
                    className="text-sm hover:text-primary"
                  >
                    Privacy & Policy
                  </a>
                  <a
                    href="/cookie-notice"
                    className="text-sm hover:text-primary"
                  >
                    Cookie Notice
                  </a>
                  <a
                    href="/gdpr-compliance"
                    className="text-sm hover:text-primary"
                  >
                    GDPR Compliance
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#434343] ">
        <div className="flex justify-between w-10/12 mx-auto py-4 items-center ">
          <h1 className="text-xs md:text-sm ">
            ©️2024 Kwanza. All Right Reserved
          </h1>
          <div className="flex space-x-2 md:space-x-4">
            {[
              {
                path: "https://www.instagram.com/kwanzadesk/",
                icon: <FaInstagram />,
              },
              { path: "https://x.com/kwanzanetwork?lang=en", icon: <FaX /> },
              {
                path: "https://www.linkedin.com/company/kwanza-digital-network/?viewAsMember=true",
                icon: <FaLinkedin />,
              },
              {
                path: "https://m.facebook.com/KwanzaNetwork",
                icon: <FaFacebook />,
              },
            ].map((item) => {
              return (
                <a
                  href={item.path}
                  target="_blank"
                  className="size-6 md:size-10 rounded-full border border-white hover:border-primary justify-center  items-center flex"
                >
                  {item.icon}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
