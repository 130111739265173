import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div className={`fixed w-full z-50`}>
      {showMenu && (
        <div className="fixed inset-0 bg-dark">
          <div className="flex flex-col items-center p-12 justify-start h-screen text-lg space-y-6">
            <div className="flex justify-end w-full mb-24">
              <AiOutlineClose
                onClick={() => {
                  setShowMenu(false);
                }}
                className=" text-muted"
              />
            </div>
            {[
              { title: "For Marketers", path: "#formarketers" },
              { title: "For Creators", path: "#forcreators" },
              { title: "Ad Formats", path: "#adformats" },
              { title: "Login", path: "https://dashboard.kwanza.io/login" },
            ].map((item) => {
              return (
                <a
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  href={item.path}
                >
                  {item.title}
                </a>
              );
            })}

            <button
              onClick={() => {
                setShowMenu(false);
                navigate("/register/?role=publisher");
              }}
              className="py-4 border border-primary text-primary hover:bg-primary transition-all duration-200 hover:text-white rounded-full px-6"
            >
              Book a Demo
            </button>
          </div>
        </div>
      )}
      <div className="bg-[#575757] w-11/12 md:w-10/12 2xl:w-8/12 mx-auto rounded-full mt-3">
        <div className="flex justify-between items-center w-10/12 md:w-11/12 2xl:w-11/12 mx-auto py-3">
          <div className="w-3/12">
            <img
              onClick={() => {
                navigate("/");
              }}
              className=" h-8 md:h-10 cursor-pointer"
              src="/Kwanza 2.svg"
            />
          </div>

          <div className="block md:hidden">
            <AiOutlineMenu
              onClick={() => {
                setShowMenu(true);
              }}
              className="text-xl"
            />
          </div>
          <div className="w-6/12 hidden md:block">
            <div className="flex space-x-4 justify-center ">
              {[
                { title: "For Marketers", path: "#formarketers" },
                { title: "For Creators", path: "#forcreators" },
                { title: "Ad Formats", path: "#adformats" },
              ].map((item) => {
                return <a href={item.path}>{item.title}</a>;
              })}
            </div>
          </div>
          <div className="hidden md:block w-3/12">
            <div className="flex space-x-4 justify-end ">
              <div className="flex space-x-4 items-center  ">
                <div
                  onClick={() => {
                    window.open("https://dashboard.kwanza.io/login");
                  }}
                  className=" cursor-pointer hover:text-primary transition-all duration-200"
                >
                  Login
                </div>
                <button
                  onClick={() => {
                    navigate("/register/?role=marketer");
                  }}
                  className="py-3 border border-primary text-primary hover:bg-primary transition-all duration-200 hover:text-white rounded-full px-6"
                >
                  Book a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
