const AdvertiserDescription = () => {
  return (
    <div className="space-y-6 ">
      <div className="space-y-2">
        <h1 className="text-lg ">Quick Launch of your account and campaigns</h1>
        <p className="text-white text-opacity-60 text-sm">
          30 Minutes from the account setting up to campaign approval
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">
          Reach up to 10 million unique people in a week
        </h1>
        <p className="text-white text-opacity-60 text-sm">
          Get access to our media planning tool powered by AI for easy
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg">
          Multiple trading models for all creative formats
        </h1>
        <ul className=" list-inside list-disc text-sm">
          {[
            " Digital Media Placement",
            "Radio",
            "Print",
            "OOH",
            "Influencer",
            "And much more",
          ].map((item) => {
            return <li className="text-white text-opacity-60">{item}</li>;
          })}
        </ul>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">Hand picked inventories</h1>
        <p className="text-white text-opacity-60 text-sm">
          Reach your targeted audience anywhere, anytime in a single media
          platform
        </p>
      </div>
    </div>
  );
};

export default AdvertiserDescription;
