import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/mainLayout";
import HomePage from "./pages/homePage";
import TermsOfUse from "./pages/termsOfUse";
import PrivacyPolicy from "./pages/privacyPolicy";
import RegistrationPage from "./pages/registrationPage";
import CookieNotice from "./pages/cookieNotice";
import GDPRCompliance from "./pages/GDPRCompliance";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<HomePage />}></Route>
          <Route path="/register" element={<RegistrationPage />}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
          <Route path="/cookie-notice" element={<CookieNotice />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/gdpr-compliance" element={<GDPRCompliance />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
