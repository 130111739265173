const CookieNotice = () => {
  return (
    <div>
      <div className="bg-white w-full min-h-screen py-20 md:py-32 text-textColor">
        <div className="w-11/12 md:w-10/12 2xl:w-8/12 mx-auto pt-2 pb-10">
          <div>
            <h1 className="font-bold text-xl">Cookie Notice</h1>
            <p className="text-sm text-gray-600">
              Last updated: September 20, 2024
            </p>

            <p className="mt-4">
              This cookie notice (“Notice“) provides additional detail about how
              Kwanza processes information collected via our website at{" "}
              <a
                href="https://www.kwanza.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                www.kwanza.io
              </a>{" "}
              (the “Website”). It also provides additional detail about the
              choices and rights that consumers (“users“, “you“, “your“) have
              regarding how we collect and process information about them. For
              more information about how Kwanza processes information collected
              via our Platform and on our Website, as well as the choices and
              rights that consumers have regarding how we collect and process
              information about them, please see our{" "}
              <a href="/privacy-policy" className="text-primary underline">
                Privacy Policy
              </a>
              .
            </p>
            <p>
              In this Notice, we use the terms “Kwanza“, “our“, “us” and “we” to
              refer to Kwanza Media Trading Desk and our affiliates.
            </p>

            <h2 className="font-bold text-md mt-4">
              Use of Cookies and Similar Technologies on the Website
            </h2>
            <p>
              This Website uses technologies such as cookies, pixels, and
              scripts, which are placed by Kwanza and our affiliates, partners,
              and other service providers (e.g., video providers and email
              service providers). These technologies collect information to
              improve the functionality of our Website, analyse trends,
              administer the Website, track a visitor’s movements around the
              Website and to gather information about our Website visitors as a
              whole. We may receive reports based on the use of these
              technologies by these companies on an individual as well as
              aggregated basis.
            </p>
            <p>
              We also partner with one or more third-party ad networks to either
              display advertising on our Website or to manage our advertising on
              other websites. Our ad network partners use cookies, pixels, and
              other ad-targeting technologies to collect information about your
              activities on our Website to provide you with targeted advertising
              based upon your interests. If you wish to not have this
              information used for the purpose of serving you targeted ads, you
              may opt out of interest-based advertising by using the{" "}
              <a
                href="http://optout.networkadvertising.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                Network Advertising Initiative opt-out tool
              </a>
              . Please note that you will still see ads if you opt out. The ads
              will just not be as relevant to you.
            </p>

            <h2 className="font-bold text-lg mt-4">
              Cookies Set via the Website
            </h2>
            <ul className="list-outside list-disc pl-8">
              {/* Cookie Descriptions */}
            </ul>

            <h2 className="font-semibold text-md mt-4">
              How to Reject Cookies
            </h2>
            <p>
              If you do not wish for us to install cookies on your device for
              the purposes described above, you may change the settings on your
              browser to block or limit cookies. For more information, please
              see{" "}
              <a
                href="http://www.aboutads.info/consumers"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                http://www.aboutads.info/consumers
              </a>
              .
            </p>
            <p>
              As noted above, cookie management tools provided by your browser
              will not remove Flash cookies. To learn how to manage privacy and
              storage settings for Flash cookies,{" "}
              <a
                href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                click here
              </a>
              .
            </p>
            <p>
              Please note that if you block or limit cookies, you may not be
              able to use all of the features of the Website.
            </p>

            <h2 className="font-semibold text-md mt-4">Notice Updates</h2>
            <p>
              From time to time, Kwanza may revise this Notice. The date of the
              last update can be found at the top of this Notice. If we make
              revisions that materially change or affect whether or how we
              collect or use personal information, we will notify you by email
              (if, as a user of our Website, you have provided us your email
              address) or by means of a notice on our Website prior to the
              change becoming effective. Therefore, you should review the
              Website periodically so that you are up-to-date on our most
              current policies and practices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;
