const PublisherDescription = () => {
  return (
    <div className="space-y-6 ">
      <div className="space-y-2">
        <h1 className="text-lg ">Monetise your contents</h1>
        <p className="text-white text-opacity-60 text-sm">
          Turn your visitors into a money making machine
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">Compatible formats and wide selection </h1>
        <ul className=" list-inside list-disc text-sm">
          {[
            "Native ads",
            "Push notifications",
            "Inpage traffic",
            "Popunder",
          ].map((item) => {
            return <li className="text-white text-opacity-60">{item}</li>;
          })}
        </ul>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg">API full customization of ad units </h1>
        <p className="text-white text-opacity-60 text-sm">
          Add our 100% local inventories to your platforms
        </p>
      </div>
      <div className="space-y-2">
        <h1 className="text-lg ">Get access to a realtime dashboard</h1>
        <p className="text-white text-opacity-60 text-sm">
          Get visibility of your revenue, campaign passing through your property
          and much more
        </p>
      </div>
    </div>
  );
};

export default PublisherDescription;
