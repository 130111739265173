import { delay, motion, stagger } from "framer-motion";

const AdFormatSection = () => {
  const staggerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      ease: "linear",
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const variants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  return (
    <div>
      <motion.div
        variants={staggerVariants}
        initial="hidden"
        transition="transition"
        whileInView="visible"
        className="grid grid-cols-12 gap-8 mt-12"
      >
        {[
          {
            icon: "/icons/tv-01.png",
            title: "Television",
            description:
              "Deliver your brand’s story with high-impact visuals and reach audiences on leading TV channels and segments in Tanzania and beyond",
          },
          {
            icon: "/icons/radio-02.png",
            title: "Radio",
            description:
              "Maximising your message’s impact through engaging, localised content on top regional and national radio stations.",
          },
          {
            icon: "/icons/camera-video.png",
            title: "Influencers",
            description:
              "Partner with trusted influencers to amplify your brand through authentic, engaging content directly to their dedicated followers.",
          },
          {
            icon: "/icons/billboard 1.png",
            title: "Billboards",
            description:
              "Gain massive visibility with strategic billboard placements, reaching commuters and pedestrians in high-traffic areas.",
          },
          {
            icon: "/icons/news-01.png",
            title: "Prints",
            description:
              "Leverage print media to engage readers with credible and timeless content in leading newspapers and magazines.",
          },
          {
            icon: "/icons/seo.png",
            title: "Direct Placement",
            description:
              "Target your audience precisely with direct placements across premium media outlets, ensuring maximum engagement and brand recognition.",
          },
          {
            icon: "/icons/change-screen-mode.png",
            title: "Displays",
            description:
              "Target your audience precisely with direct placements across premium media outlets, ensuring maximum engagement and brand recognition.",
          },
          {
            icon: "/icons/thumbs-up.png",
            title: "Social Media",
            description:
              "Social media advertising provides broad reach with targeted demographics, high engagement through interactive content.",
          },
          {
            icon: "/icons/seo.png",
            title: "SEO",
            description:
              "Boost your online presence through optimized social media campaigns and SEO strategies, driving engagement and organic growth.",
          },
        ].map((item, index) => {
          return (
            <motion.div
              variants={variants}
              key={item.title}
              className="col-span-6 md:col-span-3 cursor-pointer"
            >
              <div className="flex flex-col items-start space-y-1">
                <div className="size-20 bg-[#2A2A2A] p-6 rounded-2xl flex justify-center items-center">
                  <img className="" src={item.icon} alt={item.title} />
                </div>
                <p className="text-white font-bold text-base md:text-lg pt-4">
                  {item.title}
                </p>
                <p className="text-muted text-sm md:text-base ">
                  {item.description}
                </p>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default AdFormatSection;
