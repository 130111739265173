const GDPRCompliance = () => {
  return (
    <div className="bg-white w-full min-h-screen py-20 md:py-32 text-textColor">
      <div className="w-11/12 md:w-10/12 2xl:w-8/12 mx-auto pt-2 pb-10">
        <div>
          <h1 className="font-bold text-xl">GDPR Compliance Notice</h1>
          <p className="text-sm text-gray-600">
            Last updated: September 20, 2024
          </p>

          <p className="mt-4">
            This GDPR (General Data Protection Regulation) compliance notice
            provides information about how Kwanza collects, retains, and
            processes personal data when you provide your consent for marketing
            communications. For more details, please refer to our Privacy
            Policy.
          </p>

          <h2 className="font-bold text-md mt-4">
            Collection and Use of Personal Data
          </h2>
          <p>
            By providing your consent, you agree that Kwanza may collect,
            retain, and process your personal data to deliver marketing
            communications, which may be sent by email, phone, mail, or other
            channels, and sometimes through third parties like event organizers.
          </p>

          <h2 className="font-bold text-md mt-4">Purpose of Data Processing</h2>
          <ul className="list-outside list-disc pl-8">
            {[
              "To understand your business.",
              "To know or infer your interest in marketing products and services.",
              "To enrich your personal data with information obtained from third parties.",
              "To segment you with similar individuals.",
              "To provide you with information and offers about Kwanza’s products and services.",
              "To offer you potential business opportunities.",
              "To invite you to events, educational opportunities, or training opportunities.",
              "To offer you information resources such as whitepapers, reports, videos, and webinars.",
              "To provide you with announcements about Kwanza or notify you about Kwanza’s participation in events.",
              "To provide you with news and opinions about the marketing and advertising industries.",
              "To ask you to share Kwanza information, offers, and invitations with others.",
              "To attribute your responses or actions to specific marketing campaigns, activities, and channels.",
            ].map((purpose, index) => (
              <li key={index} className="pt-2">
                {purpose}
              </li>
            ))}
          </ul>

          <h2 className="font-bold text-md mt-4">
            Types of Personal Data Collected
          </h2>
          <p>
            Your personal data may include details such as name, business email,
            business phone, company, title, region, IP address, and business
            information like industry, size, revenue, and address. We may also
            process survey responses, questions, comments, or other data you
            provide.
          </p>

          <h2 className="font-bold text-md mt-4">
            Third-Party Service Providers
          </h2>
          <p>
            Some marketing communications and applications may involve
            third-party service providers such as MailChimp and Outreach. We may
            share your data with these providers to carry out the purposes
            described above, and data may be transferred to Tanzania and other
            countries.
          </p>

          <h2 className="font-bold text-md mt-4">
            Data Rights and Consent Withdrawal
          </h2>
          <p>
            If you wish to withdraw your consent for all Kwanza marketing or
            request to modify, correct, or delete your data, please email{" "}
            <a href="mailto:info@kwanza.co.tz" className="text-primary">
              info@kwanza.co.tz
            </a>
            . You can also unsubscribe from emails using the links provided in
            each message.
          </p>

          <p className="mt-4">
            To learn more about GDPR, visit the European Commission's website
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              {" "}
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default GDPRCompliance;
