import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ValuesSection from "../components/valuesSection";
import ValuesSection2 from "../components/valuesSection2";
import AdFormatSection from "../components/adFormatSection";
import { useNavigate } from "react-router-dom";
import { partners } from "../utils/constants";
const HomePage = () => {
  const [width, setWidth] = useState();
  const textAnimationContainer = useRef();
  const plaformParagraph = `Kwanza leverages the force of high-quality media, data accuracy, and imaginative creativity to ensure sustainable business achievements.`;
  const { scrollY } = useScroll();
  const [scrollRange, setScrollRange] = useState([2800, 3300]);
  const [translateRange, setTranslateRange] = useState([
    -1,
    plaformParagraph.length,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        // Mobile screen settings
        setScrollRange([3800, 4400]);
        setTranslateRange([-1, plaformParagraph.length]);
      } else {
        // Desktop settings
        setScrollRange([2500, 3300]);
        setTranslateRange([-1, plaformParagraph.length]);
      }
    };

    // Set initial values
    handleResize();

    // Update on resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [plaformParagraph.length]);

  const translateX = useTransform(scrollY, scrollRange, translateRange);

  const [currentChar, setCurrentChar] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  useMotionValueEvent(scrollY, "change", (value) => {
    console.log(value);
    setScrollValue(value);
    console.log(value);
    setCurrentChar(parseInt(translateX.get()));
  });
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.scrollWidth / 2);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div className=" pt-16 flex flex-col justify-center h-[83vh] md:h-[88vh] bg-cubic-gradient  ">
          <div className="w-11/12  md:w-9/12 mx-auto space-y-4">
            <h1 className=" text-4xl md:text-6xl 2xl:text-6xl leading-none md:leading-tight 2xl:leading-snug font-bold text-center">
              The All-in-One Media Marketplace for smarter{" "}
              <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent ">
                Marketers
              </span>
              ,{" "}
              <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Agencies
              </span>{" "}
              and{" "}
              <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Creators
              </span>
            </h1>
            <p className=" text-lg md:text-xl text-center text-white text-opacity-65 md:text-opacity-100 md:text-muted pb-3 md:pb-12 px-8 md:px-36 2xl:px-72">
              Kwanza is powered by Machine Learning for Seamless Planning,
              Scheduling, Buying, Monitoring, Reporting, and Insights.
            </p>
            <div className="flex justify-center mt-0 md:mt-8 space-x-4">
              <button
                onClick={() => {
                  navigate("/register?role=marketer");
                }}
                className="bg-primary py-3 hover:scale-105 transition-all px-8 font-medium text-sm md:text-base rounded-full "
              >
                BOOK A DEMO
              </button>
              <a
                href="#adformats"
                className=" border-primary text-primary border-2 py-3 hover:scale-105 transition-all px-8 font-medium text-sm md:text-base rounded-full "
              >
                LEARN MORE
              </a>
            </div>
            <div className="text-center">
              <a className="underline text-center cursor-pointer">
                See how it works
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary py-4 overflow-hidden ">
        <motion.div
          ref={containerRef}
          animate={{
            x: [0, -width],
            transition: {
              repeat: Infinity,
              duration: 16,
              repeatType: "loop",
              ease: "linear",
            },
          }}
          className="flex  "
        >
          {[...partners, ...partners].map((item, index) => {
            return (
              <img
                src={item}
                className=" object-fit h-8 md:h-12 px-2 md:px-20"
              />
            );
          })}
        </motion.div>
      </div>
      <div id="formarketers"></div>
      <div className="bg-white py-12 text-textColor">
        <div className=" hidden md:block">
          <ValuesSection />
        </div>
        <div className=" block md:hidden">
          <ValuesSection2 />
        </div>

        <div id="adformats" className=" bg-dark py-12 md:py-24 ">
          <div className="w-10/12 mx-auto text-white">
            <div className=" w-full md:w-5/12 2xl:w-4/12 ">
              <h1 className=" text-2xl md:text-3xl font-bold">
                Engage users with
                <br />
                high-converting ad formats
              </h1>
            </div>
            <AdFormatSection />
          </div>
        </div>
        <motion.div className="text-black text-2xl">
          {translateX > 40}
        </motion.div>
        <div className=" w-10/12 md:w-9/12 2xl:w-7/12 mx-auto py-24 md:py-48 ">
          <div ref={textAnimationContainer} className=" space-y-2">
            <h1 className="text-primary  font-bold text-base md:text-lg uppercase">
              OUR PLATFORM
            </h1>
            <motion.div>
              <p className="  text-2xl md:text-5xl font-bold ">
                {plaformParagraph.split(/(?!^)/).map((item, index) => (
                  <motion.span
                    key={index} // Ensure each character has a unique key
                    style={{
                      color: currentChar > index ? "black" : "#D1D5DA",
                    }}
                  >
                    {item}
                  </motion.span>
                ))}
              </p>
            </motion.div>
          </div>
        </div>
        <div className=" w-10/12 mx-auto">
          <div
            style={{
              background: "url('/joinusbg.png')",
              backgroundSize: "cover",
            }}
            className="bg-dark p-6 md:p-24  rounded-2xl md:rounded-2xl  content-none grid grid-cols-12  items-center"
          >
            <div className=" col-span-12 md:col-span-6 ">
              <h1 className="text-white font-bold text-center md:text-start text-xl 2xl:text-3xl ">
                Join Us Today
              </h1>
              <p className="text-muted mt-2 text-center md:text-start md:mt-4">
                You’re just momet away from unlocking high-performing ad
                campaigns and attracting a surge of quality traffic. Step into
                your newfound growth zone!
              </p>
            </div>
            <div className=" pt-4 md:pt-0 col-span-12 md:col-span-6 flex flex-col  md:flex-row  space-y-3 md:space-y-0 md:space-x-3 justify-end ">
              <div
                onClick={() => {
                  navigate("/register/?role=marketer");
                }}
                className="bg-primary text-sm text-center  hover:scale-105 transition-all cursor-pointer  py-3 px-8 font-medium rounded-md text-white "
              >
                I'm a Marketer
              </div>
              <div
                onClick={() => {
                  navigate("/register/?role=creator");
                }}
                className="bg-primary text-sm text-center hover:scale-105 transition-all cursor-pointer  py-3 px-8 font-medium rounded-md text-white "
              >
                i'm a Creator
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
