export const partners = [
  "/logos/Logo 2.png",
  "/logos/logo 3.png",
  "/logos/logo 4.png",
  "/logos/Logo 5.png",
  "/logos/Logo 7.png",
  "/logos/Logo 8.png",
  "/logos/Logo 9.png",
  "/logos/Logo 16.png",
];
export const outlets = [
  "Billboards",
  "Influencers",
  "Social Media",
  "Displays",
  "Direct Placements",
  "Audio Ads",
  "Search Ads",
  "Radio",
  "Television",
  "Print",
];
