import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { FaCheck } from "react-icons/fa";
import Spinner from "../components/spinner";
import AdvertiserDescription from "../components/advertiserDescription";
import PublisherDescription from "../components/publisherDescription";
import { sendnewMarketerEmail } from "../controllers/emailControllers";
import { registerUser } from "../controllers/userControllers";
import toast from "react-hot-toast";
import { useGetParams } from "../utils/getParams";

const RegistrationPage = () => {
  const { role } = useGetParams();

  const [selectedTab, setSelectedTab] = useState(role == "marketer" ? 0 : 1);
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const animate = {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  };
  const initial = {
    scale: 0,
    opacity: 0,
  };
  const exit = {
    scale: 0.7,
    opacity: 0,
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object(
    selectedTab == 0
      ? {
          name: Yup.string(),
          phone: Yup.string(),
          email: Yup.string().required(),
          company: Yup.string().required(),
        }
      : {
          name: Yup.string(),
          phone: Yup.string(),
          email: Yup.string().required(),
        }
  );
  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        // alert(values.company);
        setLoading(true);
        if (selectedTab == 0) {
          sendnewMarketerEmail({
            company: values.company,
            email: values.email,
            name: values.name,
            phone: values.phone,
          })
            .then((e) => {
              setLoading(false);
              resetForm();
              toast.success("Booked successfully");
            })
            .catch((e) => {
              toast.error(e.response.data.message);
              setLoading(false);
            });
        } else {
          registerUser({
            email: values.email,
            name: values.name,
            phone: values.phone,
          })
            .then((e) => {
              setLoading(false);
              resetForm();
              setRegistered(true);
              window.open(
                `https://dashboard.kwanza.io/confirm/${values.email}`
              );
            })
            .catch((e) => {
              toast.error(e.response.data.message);
              setLoading(false);
            });
        }
      }}
      validationSchema={validationSchema}
      initialValues={{
        name: "",
        email: "",
        phone: "",
        company: "",
        platform: "",
        terms: "",
      }}
    >
      {({
        errors,
        values,
        isSubmitting,
        resetForm,
        handleChange,
        handleSubmit,
        touched,
      }) => (
        <div className=" bg-dark text-white min-h-screen ">
          <div className="w-10/12 2xl:w-10/12 grid grid-cols-12 mx-auto py-20 md:py-32">
            <div className="  col-span-12 md:col-span-5 ">
              <button className="bg-lightDark rounded-lg">
                <div className="flex py-1 px-1 ">
                  {[
                    { item: "For Marketers", id: "marketer" },
                    { item: "For Creators", id: "creator" },
                  ].map((item, index) => {
                    return (
                      <button
                        onClick={(e) => {
                          setSelectedTab(index);
                          navigate(`/register/?role=${item.id}`);
                        }}
                        id={item.id}
                        className={`${
                          selectedTab == index ? "bg-primary " : ""
                        } text-white py-3 text-sm 2xl:text-base rounded-lg px-3`}
                      >
                        {item.item}
                      </button>
                    );
                  })}
                </div>
              </button>
              <div className="mt-12 md:block hidden">
                {selectedTab == 0 ? (
                  <AdvertiserDescription />
                ) : (
                  <PublisherDescription />
                )}
              </div>
            </div>
            <div className=" col-span-2 2xl:col-span-3 hidden md:block"></div>
            <div className=" col-span-12 md:col-span-5 2xl:col-span-4">
              <AnimatePresence mode="wait">
                {selectedTab == 0 && (
                  <motion.div
                    key="tab-0"
                    animate={animate}
                    exit={exit}
                    initial={initial}
                    className="bg-lightDark p-6 md:p-12 rounded-2xl md:mb-0 mb-24 md:mt-0 mt-4"
                  >
                    <form onSubmit={handleSubmit}>
                      <h1 className="text-2xl md:text-3xl font-bold">
                        Book a Demo
                      </h1>
                      <p className="text-base mb-5 mt-2">Marketer</p>
                      <div className="space-y-5">
                        <div className="space-y-1">
                          <p>Company Name</p>
                          <input
                            onChange={handleChange}
                            value={values.company}
                            name="company"
                            placeholder="Enter company name"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">
                            {errors.company}
                          </p>
                        </div>
                        <div className="space-y-1">
                          <p>Name</p>
                          <input
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            placeholder="Enter full name"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.name}</p>
                        </div>
                        <div className="space-y-1">
                          <p>Phone Number</p>
                          <input
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            placeholder="Enter phone number"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.phone}</p>
                        </div>
                        <div className="space-y-1">
                          <p>Email Address</p>
                          <input
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            placeholder="Enter email address"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.email}</p>
                        </div>
                        <div>
                          <div className="flex space-x-2 items-start">
                            <input
                              required
                              type="checkbox"
                              className=" bg-lightDark checked:bg-primary mt-1 border-white focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary "
                            />
                            <p className="text-sm">
                              I accept{" "}
                              <span
                                onClick={() => {
                                  navigate("/terms-of-use");
                                }}
                                className="font-bold cursor-pointer text-primary"
                              >
                                Terms of Use
                              </span>{" "}
                              and
                              <span
                                onClick={() => {
                                  navigate("/privacy-policy");
                                }}
                                className="font-bold cursor-pointer text-primary"
                              >
                                {" "}
                                Privacy policy
                              </span>{" "}
                              and agree to receive newsletters.
                            </p>
                          </div>
                          {/* <p className="text-sm text-red-500">{errors.terms}</p> */}
                        </div>

                        <button
                          id="booknow_advertiser"
                          type="submit"
                          className="bg-primary hover:bg-opacity-90 w-full flex justify-center items-center  text-white rounded-lg py-3 px-4 text-center font-semibold"
                        >
                          {loading ? <Spinner /> : "Book Now"}
                        </button>

                        {/* <ToastContainer /> */}
                      </div>
                    </form>
                  </motion.div>
                )}
                {selectedTab == 1 && (
                  <motion.div
                    key="tab-1"
                    animate={animate}
                    exit={exit}
                    initial={initial}
                    className="bg-lightDark p-6 md:p-12 rounded-2xl md:mb-0 mb-24 md:mt-0 mt-4"
                  >
                    <form onSubmit={handleSubmit}>
                      <h1 className="text-2xl md:text-3xl font-bold">
                        Register
                      </h1>
                      <p className="text-base mb-5 mt-2">Creator</p>
                      <div className="space-y-5">
                        <div className="space-y-1">
                          <p>Name</p>
                          <input
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            placeholder="Enter full name"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.name}</p>
                        </div>
                        <div className="space-y-1">
                          <p>Phone Number</p>
                          <input
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            placeholder="Enter phone number"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.phone}</p>
                        </div>
                        <div className="space-y-1">
                          <p>Email Address</p>
                          <input
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            placeholder="Enter email address"
                            className="form-style-light"
                          />
                          <p className="text-sm text-red-500">{errors.email}</p>
                        </div>

                        <div className="flex space-x-2 items-start">
                          <input
                            required
                            type="checkbox"
                            className=" bg-lightDark checked:bg-primary mt-1 border-white focus:ring-primary hover:bg-transparent checked:hover:bg-primary checked:focus:bg-primary   focus:border-primary "
                          />
                          <p className="text-sm">
                            I accept{" "}
                            <span
                              onClick={() => {
                                navigate("/terms-of-use");
                              }}
                              className="font-bold cursor-pointer text-primary"
                            >
                              Terms of Use
                            </span>{" "}
                            and
                            <span
                              onClick={() => {
                                navigate("/privacy-policy");
                              }}
                              clas
                              className="font-bold cursor-pointer text-primary"
                            >
                              {" "}
                              Privacy policy
                            </span>{" "}
                            and agree to receive newsletters.
                          </p>
                        </div>

                        <button
                          id="booknow_publisher"
                          type="submit"
                          className="bg-primary hover:bg-opacity-90 w-full flex justify-center items-center  text-white rounded-lg py-3 px-4 text-center font-semibold"
                        >
                          {loading ? <Spinner /> : "Create an account"}
                        </button>
                      </div>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default RegistrationPage;
